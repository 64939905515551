import endOfDay from 'date-fns/endOfDay';

import { convertToZurichTz, getDateZurich } from '@utils/date';

export const selectGoodies = state => state.goodies.results;
export const selectActiveGoodies = state =>
  state.goodies.results.filter(
    ({ validUntil, dateUsed }) =>
      !(endOfDay(getDateZurich()) > convertToZurichTz(endOfDay(new Date(validUntil)))) && !dateUsed
  );
export const selectIsFetchingGoodies = state => state.goodies.isFetching;
export const selectGoodiesHasMore = state => state.goodies.hasMore;
export const selectGoodiesListOffset = state => state.goodies.offset;

export const selectIsOpening = goodieId => state =>
  state.goodies.results.find(({ id }) => id === goodieId).isOpening;
export const selectIsOpened = goodieId => state =>
  state.goodies.results.find(({ id }) => id === goodieId).isOpen;
export const selectGoodiesDetails = id => state => state.goodies.goodiesDetails[id];
export const selectIsFetchingDetails = state => state.goodies.isFetchingDetails;
