import React from 'react';
import { useTheme } from 'styled-components';

import PriceText from '@common/components/PriceText';
import ProgressBar from '@common/components/ProgressBar';
import { GiftCardSubtitle, TimeleftDays, TimeleftTitle } from '../RemovablePart.styled';

import { RemovablePartWrapper, WrapperSmaller } from './RemovablePartPlaceholder.styled';

const RemovablePartPlaceholder = () => {
  const { colors } = useTheme();

  return (
    <WrapperSmaller data-testid="invalidCouponBottom">
      <RemovablePartWrapper>
        <GiftCardSubtitle>
          <PriceText>&nbsp;</PriceText>
        </GiftCardSubtitle>

        <TimeleftTitle>
          <TimeleftDays>&nbsp;</TimeleftDays>
        </TimeleftTitle>

        <ProgressBar
          color={colors.grey[100]}
          backgroundColor={colors.grey[100]}
          progress={0}
        />
      </RemovablePartWrapper>
    </WrapperSmaller>
  );
};

export default RemovablePartPlaceholder;
