import styled from 'styled-components';

import { getThemeTransition, getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';

export const ActiveCardWrapper = styled.div`
  overflow: hidden;
  opacity: ${({ isAnimationPlaying }) => (isAnimationPlaying ? '0' : '1')};
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: ${getThemeTransition('opacity', 'slow')};
`;

export const ActiveCardContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem 2rem 3rem;
`;

export const ActiveCardDescription = styled(Typography).attrs({ variant: 'body2' })`
  margin: 0;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  white-space: pre-line;
`;

export const ActiveCardTitle = styled(Typography).attrs({ variant: 'h4' })`
  margin: 0;
  margin-bottom: 1rem;
  background: ${getThemeColor('brand.goldGradient')};
  -webkit-text-fill-color: transparent;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
`;
