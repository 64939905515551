import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { COUPON_GIFT_VIDEO } from '@common/images';
import Button, { BUTTON_VARIANTS } from '@common/components/Button';

import {
  UnpackedCardMessage,
  AnimationVideo,
  UnpackedCardWrapper,
  UnpackedCardContentWrapper,
} from './UnpackedCard.styled';

const UnpackedCard = ({ onGiftOpen, isAnimationPlaying, setIsAnimationPlaying }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  const handleOpenClick = () => {
    if (!isAnimationPlaying) {
      setIsAnimationPlaying(true);
      videoRef.current.play();
    }
  };

  return (
    <UnpackedCardWrapper>
      <AnimationVideo
        onEnded={onGiftOpen}
        ref={videoRef}
        playsInline
      >
        <source
          src={COUPON_GIFT_VIDEO}
          type="video/mp4"
        />
      </AnimationVideo>
      <UnpackedCardContentWrapper isAnimationPlaying={isAnimationPlaying}>
        <UnpackedCardMessage>
          {t('giftCard.youHaveNewGift', 'Du hast ein neues Geschenk!')}
        </UnpackedCardMessage>
        <Button
          onClick={handleOpenClick}
          type="button"
          variant={BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL}
        >
          {t('giftCard.openGift', 'Mehr erfahren')}
        </Button>
      </UnpackedCardContentWrapper>
    </UnpackedCardWrapper>
  );
};

UnpackedCard.propTypes = {
  onGiftOpen: PropTypes.func.isRequired,
  isAnimationPlaying: PropTypes.bool.isRequired,
  setIsAnimationPlaying: PropTypes.func.isRequired,
};

export default UnpackedCard;
