import React from 'react';
import PropTypes from 'prop-types';

import RemovablePartActive from './RemovablePartActive';
import RemovablePartPlaceholder from './RemovablePartPlaceholder';

const RemovablePart = ({ isValid, ...props }) =>
  (isValid ? <RemovablePartActive {...props} /> : <RemovablePartPlaceholder />);

RemovablePart.propTypes = {
  isValid: PropTypes.bool.isRequired,
};

export default RemovablePart;
