import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';
import { Wrapper } from '@common/components/Voucher/Voucher.styled';
import { COUPON_DOT_PATTERN } from '@common/images';

export const WrapperSmaller = styled(Wrapper)`
  width: 100%;
  ${({ theme }) => theme.getFluidAttribute('min-height', 10, 11)};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 0.4rem;
    background-image: ${`url(${COUPON_DOT_PATTERN})`};
    background-size: 0.8rem;
    background-position-y: bottom;
  }
`;

export const RemovablePartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TimeleftTitle = styled(Typography).attrs({ variant: 'paragraph1' })`
  display: inline-block;
  margin: 0.1rem 0 0.4rem;
`;

export const TimeleftDays = styled(Typography).attrs({ customAs: 'span', variant: 'h5' })`
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
`;

export const GiftCardSubtitle = styled(Typography).attrs({ variant: 'body2' })`
  margin: 0;
  color: ${getThemeColor('bluegrey.80')};
  text-align: center;
`;

export const Dots = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 0.4rem;
  background-image: ${`url(${COUPON_DOT_PATTERN})`};
  background-size: 0.8rem;
  background-position-y: bottom;
`;
